import {brandingStore} from '@/store/__STORE_branding';

export default {
  data() {
    return {
      defaultBkImage: require('@/assets/img/SV-rebrand-hero.png'),
      backImageStyle: '',
    };
  },
  methods: {
    applyBranding(brand) {
      this.$emit('set-room-brand', brand);
      brandingStore.setLogo({
        logo: brand.logo,
        level: brand.level,
      });
      brandingStore.setGuestWelcomeMessage(brand.guest_welcome_message);
      const bkImage = (brand.hero_image ? brand.hero_image : this.defaultBkImage);
      this.backImageStyle = `background-image: url(${bkImage})`;

      this.changeFavicon(brand);
    },
    disableBranding() {
      this.$emit('set-room-brand', null);
    },
    changeFavicon(brand) {
      const link = document.createElement('link');
      const oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      if (brand.favicon) {
        link.href = brand.favicon;
      } else {
        link.href = '';
      }
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    },
  },
};
