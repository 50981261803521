<template>
  <div
    v-if="showMoreWays"
    class="more-ways"
  >
    <div
      v-if="showMoreWays == 'teams' && meetingRoom.skype"
      class="join-with teams"
    >
      <h3><img src="@/assets/img/icons/microsoft_teams_logo.svg"> Join with Teams</h3>
      <ol>
        <li>Open <em>Microsoft Teams</em>.</li>
        <li>Add <u>{{ meetingRoom.skype.address }}</u> to your contacts.</li>
        <li>Click call to start a video meeting.</li>
      </ol>
      <p><b>Please note: due to restrictions by Microsoft this only works on the desktop application.</b></p>
      <button
        class="btn btn-primary"
        title="Open Teams"
        @click.prevent="openApp('teams')"
      >
        Open Teams
      </button>
    </div>

    <div
      v-if="showMoreWays == 'skype' && meetingRoom.skype"
      class="join-with skype"
    >
      <h3><img src="@/assets/img/icons/skype_logo.svg"> Join with Skype for Business</h3>
      <ol>
        <li>Open <em>Skype for Business</em>.</li>
        <li>Add <u>{{ meetingRoom.skype.address }}</u> to your contacts.</li>
        <li>Click call to start a video meeting.</li>
      </ol>
    </div>

    <div
      v-if="showMoreWays == 'phone' && meetingRoom.has_phone_license"
      class="join-with phone"
    >
      <h3><img src="@/assets/img/icons/phone_outline.svg"> Join with telephone</h3>
      <ol>
        <li>Dial the conference number.</li>
        <li>
          Pick your local number below:
          <select
            v-if="!isFetchingPhones"
            v-model="selectedPhone"
            class="form-control"
          >
            <option
              v-for="phone in phoneNumbers"
              :key="phone.phone_number"
              :value="phone"
            >
              {{ phone.country }}
              {{ phone.phone_number }}
            </option>
          </select>
        </li>
        <li>
          When prompted, enter this conference ID
          <input
            ref="conference_id"
            class="form-control read-only"
            type="text"
            readonly
            :size="meetingRoom.phone.conference_id.length"
            :value="meetingRoom.phone.conference_id"
            @click.prevent="copyToClipboard($refs.conference_id)"
          >
        </li>
      </ol>
      <button
        class="btn btn-primary"
        title="Join by telephone"
        @click.prevent="openDevice('phone')"
      >
        Join by telephone
      </button>
    </div>

    <div
      v-if="showMoreWays == 'conference' && meetingRoom.has_video_device_license"
      class="join-with phone"
    >
      <h3><img src="@/assets/img/icons/conference_outline.svg"> Join with a conference device</h3>
      <ol>
        <li>Open up your dialer on your device.</li>
        <!-- eslint-disable-next-line -->
        <li>Enter
          <input
            ref="alphanumeric_address"
            class="form-control read-only"
            type="text"
            readonly
            :size="meetingRoom.alpha_numeric.address.length"
            :value="meetingRoom.alpha_numeric.address"
            @click.prevent="copyToClipboard($refs.alphanumeric_address)"
          >
          using your remote control or keypad*.
        </li>
        <li>Once the address is in the dialer, click Start call to dial in.</li>
      </ol>
      <p>
        *If you have a numeric device you can enter
        <input
          ref="numeric_address"
          class="form-control read-only"
          type="text"
          readonly
          :size="meetingRoom.numeric.address.length"
          :value="meetingRoom.numeric.address"
          @click.prevent="copyToClipboard($refs.numeric_address)"
        >
      </p>
    </div>

    <div
      v-if="showMoreWays == 'xr' && meetingRoom.has_xr_license"
      class="join-with xr"
    >
      <h3><img src="@/assets/img/icons/xr_device.svg"> Join with XR Device</h3>
      <ol>
        <li>Initialise your XR device.</li>
        <li>Start your camera.</li>
        <li>Scan the <em>Meeting QR Code</em> on the right of this page.</li>
      </ol>
    </div>


    <div class="backButton">
      <a
        class="branded-link"
        href=""
        @click.prevent="closeInstructions()"
      >
        <img
          v-svg-inline
          src="@/assets/img/icons/ic_go-back.svg"
        >
        Back to Join Call
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CopyToClipboardMixin from '@/mixins/CopyToClipboardMixin';

export default {
  name: 'MoreWaysToJoin',
  mixins: [
    CopyToClipboardMixin,
  ],
  props: {
    showMoreWays: {
      type: String,
      default: null,
    },
    meetingRoom: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isFetchingPhones: true,
      phoneNumbers: [],
      selectedPhone: {
        country: 'United Kingdom',
        phone_number: '+441133207090',
      },
    };
  },
  watch: {
    showMoreWays() {
      if (this.showMoreWays == 'phone') {
        this.getPhones();
      }
    },
  },
  mounted() {
    if (this.showMoreWays == 'phone') {
      this.getPhones();
    }
  },
  methods: {
    openApp(app) {
      let url = null;
      if (app == 'teams') {
        url = 'msteams://l/calls/saved-calls?contacts=true';
      }
      if (app == 'skype') {
        url = 'sip:' + this.meetingRoom.skype.address;
      }


      if (url) {
        const win = window.open(url, '_blank');
        win.focus();
      }
    },
    openDevice(app) {
      if (app == 'phone') {
        window.open('tel:' + this.selectedPhone.phone_number + ',' +this.meetingRoom.phone.conference_id+'#');
      }
    },
    getPhones() {
      const url = '/phone-numbers';

      axios({url: url, method: 'GET'})
          .then((resp) => {
            this.phoneNumbers = resp.data;
            this.isFetchingPhones = false;
          })
          .catch((err) => {
            this.isFetchingPhones = false;
          });
    },
    closeInstructions() {
      this.$emit('close-instructions');
    },
  },
};
</script>

<style lang="scss" scoped>
  .more-ways {
    .backButton {
      margin: 2rem 0;

      a ::v-deep {
        display: inline-block;

        svg {
          margin-right: 5px;
          vertical-align: bottom;
        }
      }
    }
    .join-with {
      h3 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 30px;
        padding-left: 50px;

        img {
          height: 40px;
          margin: 0 10px 0 -50px;
          max-width: 60px;
        }
      }

      ol {
        list-style: none;
        counter-reset: steps;
        padding-left: 0;
        margin-bottom: 30px;

        li {
          counter-increment: steps;
          margin-bottom: 15px;
          padding-left: 50px;

          &:before {
            background: #3E3E40;
            border-radius: 50%;
            color: white;
            content: counter(steps);
            display: inline-block;
            font-weight: bold;
            line-height: 0;
            margin: 0 20px 0 -50px;
            padding: 15px 0;
            text-align: center;
            width: 30px;
          }
        }
      }
      em,
      u {
        font-weight: bold;
      }

      u {
        color: var(--primary-color);
      }

      input.read-only {
        background: #e5e5e5;
        display: inline-block;
        font-size: inherit;
        padding: 0 5px;
        height: auto;
        width: auto;
      }
    }
  }
</style>
