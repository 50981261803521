import axios from 'axios';
import Vue from 'vue';
import {mainStore} from '@/store/__STORE_main';
import {authStore} from '@/store/__STORE_auth';
import BrandingMixin from './BrandingMixin';

export default {
  mixins: [BrandingMixin],
  props: {
    webrtcAlias: String,
    preloadedMeetingRoom: Object,
  },
  data() {
    return {
      isFetchingData: true,
      meetingRoom: this.preloadedMeetingRoom ? Vue.util.extend({}, this.preloadedMeetingRoom) : null,
    };
  },
  computed: {
    isLoggedIn: () => authStore.__GETTERisUserLoggedIn,
  },
  methods: {
    onJoiningFlowMounted() {
      this.$emit('disable-fullscreen');
      if (this.meetingRoom) {
        this.onMeetingRoomFetched();
        return;
      }

      this.getData();
    },
    getData() {
      mainStore.setLoading();
      const url = '/join-meeting-room/' + this.webrtcAlias;

      axios({url: url, method: 'GET'})
          .then((resp) => {
            this.meetingRoom = resp.data;
            this.onMeetingRoomFetched();
          })
          .catch((err) => {
            this.onFetchingError(err);
          });
    },
    onFetchingError(err) {
      const statusCode = err.response.status;
      const error = statusCode === 404 ? 'Meeting not found' : ('Unexpected error, code ' + statusCode);

      this.$router.push({
        name: 'join-meeting',
        params: {
          error: error,
        },
      });
    },
    onMeetingRoomFetched() {
      mainStore.setLoading();
      this.redirectIfFlowNotSupported();
      this.applyBranding(this.meetingRoom.brand);
      this.isFetchingData = false;
    },
    redirectIfFlowNotSupported() {
      const currentRoute = this.$route.name;

      const videoCodecRoutes = ['video-device-instructions', 'alpha-numeric-instructions', 'numeric-instructions'];
      if (videoCodecRoutes.indexOf(currentRoute) !== -1) {
        this.redirectIfVideoCodecNotSupported();
      }

      const skypeRoutes = ['microsoft-instructions', 'skype-instructions', 'teams-instructions'];
      if (skypeRoutes.indexOf(currentRoute) !== -1) {
        this.redirectIfSkypeNotSupported();
      }

      const phoneRoutes = ['phone-instructions'];
      if (phoneRoutes.indexOf(currentRoute) !== -1) {
        this.redirectIfPhoneNotSupported();
      }
    },
    redirectIfVideoCodecNotSupported() {
      if (!this.meetingRoom.has_video_device_license) {
        this.redirectToWelcomeGuest();
      }
    },
    redirectIfSkypeNotSupported() {
      if (!this.meetingRoom.skype) {
        this.redirectToWelcomeGuest();
      }
    },
    redirectIfPhoneNotSupported() {
      if (!this.meetingRoom.has_phone_license) {
        this.redirectToWelcomeGuest();
      }
    },
    redirectToWelcomeGuest() {
      this.$router.push({
        name: 'welcome-guest',
        params: {
          webrtcAlias: this.webrtcAlias,
          preloadedMeetingRoom: this.meetingRoom,
        },
      });
    },
    copyText(selector) {
      const node = document.querySelector(selector);
      node.setAttribute('type', 'text');
      node.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.$toast.success('Copied successfully');
        } else {
          this.$toast.error('Error, unable to copy');
        }
      } catch (err) {
        this.$toast.error('Error, unable to copy');
      }

      window.getSelection().removeAllRanges();
    },
  },
};
